@import "src/styles/variables";

.Filters {
  height: 70px;

  flex-grow: 1;
  margin-right: 30px;

  position: relative;
  border-bottom: 3px solid $bg-with-opacity;

  display: flex;

  & > div {
    margin-right: 30px;
  }
}

.FilterAction.FilterAction  {
  background-color: transparent;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.2px;
  font-size: 24px;
  height: 70px;

  &:hover {
    background-color: transparent;
    box-shadow: none;
  }

  &__Active {
    position: relative;
    &:after {
      content: '';
      width: 100%;
      height: 3px;
      background-color: $accent;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &__Focused {
    background-color: transparent !important;
    box-shadow: none !important;
    color: $accent;
  }
}

