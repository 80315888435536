@import "src/styles/variables";

.CabinetLayout {
  width: 1920px;
  height: 1080px;

  overflow: hidden;

  display: flex;

  padding: 45px 55px 0;
  background-color: $secondary;

  .CabinetMenuContainer {
    width: 350px;
    height: 100%;

    display: flex;
    flex-direction: column;

    margin-right: 100px;
  }

  .CabinetContentContainer {
    width: 100%;
    height: 100%;
  }
}