@import "src/styles/mixins";
@import "src/styles/variables";

.ProgramDatesCardInner {
  height: 60px;
  width: 300px;
  @include flex-centered;
  @include font-styles('Roboto', 28px, 500, normal);
  position: relative;

  &__Active {
    color: $accent;

    &:before {
      content: '';
      height: 5px;
      width: 100%;
      position: absolute;
      opacity: 1;
      left: 0;
      background-color: $accent;
      bottom: -21px;
    }
  }

  &__Chosen {
    border-radius: 10px;
    background-color: rgba($accent, 0.5);
  }
}

.TabWrapper {
  padding: 10px;
}

.TabFocusWrapper {
  overflow-y: unset;
  overflow-x: unset;
}

.TabFocusWrapperFocused {
  border-color: $accent;
  box-shadow: $button-active-box-shadow;
}
