@import "src/styles/variables";

$range-background-color: rgba(255, 255, 255, 0.5);
$range-secondary-color: rgba(255, 255, 255, 0.5);
$range-progress-color: $accent;
$range-tooltip-bgcolor: rgba(16, 16, 16, 0.57);

.SliderRange {
  --range-background-color: #{$range-background-color};
  --range-secondary-color: #{$range-secondary-color};
  --range-progress-color: #{$range-progress-color};
  --range-tooltip-bgcolor: #{$range-tooltip-bgcolor};

  width: 100%;

  font-size: 10px;
  font-weight: bold;

  display: flex;
  align-items: center;
  position: relative;

  &__Prepend {
    margin-right: 10px;
    text-align: right;
  }

  &__SliderWrapper {
    width: 100%;
    border: 5px solid transparent;
    border-radius: 10px;
    padding: 10px;

    &__Active {
      //border-color: $accent;
    }
  }

  &__Slider {
    width: 100%;
    height: 10px;
    position: relative;
    cursor: pointer;

    .Layer {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }

    .Background {
      right: 0;
      bottom: 0;
      background-color: var(--range-background-color);
      border-radius: 10px;
    }

    .Secondary {
      background-color: var(--range-secondary-color);
      border-radius: 10px;
    }

    .Progress {
      background-color: var(--range-progress-color);
      border-radius: 10px;
    }

    .Thumb {
      width: 15px;
      height: 15px;
      //width: 30px;
      border-radius: 50%;
      background-color: white;
      border:10px solid transparent;
      outline: none;

      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      &__Active, &__Drag, &:hover {
        //background-color: $accent;
        border-color:  $accent;
        width: 35px;
        height: 35px;
      }
    }
  }

  &__Append {
    margin-left: 10px;
    text-align: left;
  }
}
