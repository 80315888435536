.SkeletonWrapper {
  height: 400px;
  padding-bottom: 40px;
  width: 100%;
  padding-left: 180px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .SkeletonTitle {
    height: 50px;
    width: 300px;
    padding-bottom: 15px;
    margin-left: 15px;
  }

  .SkeletonBody {
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
  }

  .SkeletonCard {
    height: 100%;
    width: calc(100%/4);
    padding: 0 10px;
  }
}