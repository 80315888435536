@import 'src/styles';


.FocusWrapper {
  --width: 80px;
  --height: 80px;
  height: var(--height);
  width: var(--width);
  padding: 10px;

  &__DoubleSize {
    width: calc(var(--width) * 2);

    .Button {
      font-size: 24px;
    }
  }

  &__TripleSize {
    width: calc(var(--width) * 3);
  }

  &__SixSize {
    width: calc(var(--width) * 6);
  }
}

.KeyButton {
  width: 100%;
  height: 100%;

  border: 1px solid transparent;

  font-family: Roboto, sans-serif;
  font-size: 24px !important;
  line-height: 1 !important;
  text-align: center;

  &:hover {
    background-color: $bg-with-opacity !important;
    border-color: $accent;
  }

  &:disabled:hover {
    background-color: $bg-with-opacity !important;
    border-color: transparent;
  }
}

.FocusedButton {
  background-color: $bg-with-opacity !important;
  border-color: $accent;
}
