.NavigationCard {
  opacity: 1;

  &__Inner {
    padding: 20px;
    height: 315px;
    //background-color: #fff;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .Button {
      height: 80px;
      min-width: 240px;
      padding: 0 20px;

      &__WithMargin {
        margin-top: 40px;
      }
    }

    //&>:last-child {
    //  margin-top: 40px;
    //}
  }
}

.NavigationCardWrapper {
  display: flex;
  align-items: center;
  padding: 0 10px;

  &:last-of-type {
    margin-right: 20px;
  }
}