@import "src/styles/variables";

.Wrapper {
  padding: 0 20px;
}

.FocusWrapper {
  border-radius: 10px;
  overflow: hidden;
  border: 4px solid transparent;

  &__WithBorder {
    border-color: $bg-with-opacity;
  }

  &__Focused {
    border-color: $accent;
    box-shadow: $button-active-box-shadow;
  }

  &__Disabled {
    opacity: 0.4;
  }
}