@import "src/styles";

.Results {
  height: calc(1080px - 260px);
  width: calc(1920px - 170px);
  margin-left: 170px;
  display: flex;

  &__Empty {
    display: flex;
    align-items: center;
    justify-content: center;

    @include card-title-font-styles;
    font-size: 36px;
    font-weight: 400;
  }
}