@import "src/styles";

.Favorites {
  height: 1080px;
  width: 1920px;

  display: flex;
  flex-direction: column;

  overflow: hidden;

  padding: 0 0 0 170px;

  h1 {
    display: flex;
    align-items: center;

    height: 120px !important;
    @include card-title-font-styles;
    font-size: 46px;
    padding: 0 35px 0 10px;
    font-weight: 500;
  }

  .Container {
    height: 890px;
    width: 100%;
  }

  .List {
    display: flex;
    flex-wrap: wrap;
  }
}