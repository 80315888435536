.Rating {
  display: flex;
  align-items: center;
  height: 100%;

  color: #FFF;

  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;

  img {
    height: 100%;
  }

  span {
    margin-left: 8px;
  }
}