.PartnerLogo {
  height: 100%;
  display: flex;
  align-items: center;

  //TODO: Пересмотреть партнер лого, может разные стили? Пока выглядит плохо

  max-width: 200px;

  img {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
}