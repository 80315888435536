@import "src/styles/variables";

.Title {
  height: 34px;
  color: white;
  padding-left: 200px;

  font-family: Montserrat, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.ScrollingWrapper {
  padding: 0 0 0 170px;
  overflow-x: auto;
  overflow-y: hidden;
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
}

.ContentWrapper {
  display: flex;
  flex-direction: row;

  will-change: transform;

  transition: transform $horizontal-scroll-duration;
}
