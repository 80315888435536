.ProgramListSlider {

}

.ProgramListFocusWrapper.ProgramListFocusWrapper {
  height: 830px;
  width: 1920px;
  margin: 0 -30px;
  padding: 30px 0 0;
}
