@import "src/styles/variables";

.Up {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba($gray-900, 0.8);

  width: 120px;
  height: 120px;
  margin-right: 25px;
  margin-bottom: 25px;

  //border-radius: 140px 0 0 0;
  border-radius: 50%;
  .UpButton {
    position: absolute;
    bottom: 20px;
    right: 20px;
    height: 80px;
    width: 80px;
    border-radius: 50%;
  }

}