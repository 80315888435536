.Live {
  position: relative;

  &:before {
    position: absolute;

    top: 2px;
    right: 12px;

    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;

    animation: loop 2s 0s ease infinite;
  }

  @keyframes loop {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, .6);
    }

    100% {
      box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
  }
}

