@import "src/styles/variables";
@import "src/styles/mixins";


.FocusWrapper {
  padding: 20px;
}

.NeedPackageCardInner {
  display: flex;
  justify-content: space-between;
  width: 1600px;
  height: 500px;

  .Image {
    position: relative;
    width: 385px;
    height: 100%;

    img {
      height: 100%;
      object-fit: cover;
    }

    .PackageTitle {
      position: absolute;
      bottom: 20px;
      left: 30px;

      @include font-styles('Montserrat', 28px, 700, 120%);
    }
  }

  .InfoWrapper {
    height: 100%;
    width: 1215px;

    @include font-styles('Roboto', 28px, 500, 120%);

    border-spacing: 0 0;

    tr {
      background-color: rgba(black, 0.7);

      &:nth-child(odd) {
        //background-color: $gray-900;
      }
    }

    th {
      text-align: start;
      vertical-align: top;
      width: 330px;
      color: #929090;
      padding: 20px 0 20px 30px;
    }

    td {
      text-align: start;
      vertical-align: top;
      @include text-ellipsis(7);
      padding: 20px 30px 20px 0;
    }
  }
}