@import "src/styles/mixins";
@import "src/styles/variables";

.ProgramListItemCard {
  padding: 0 20px 20px;

  &:last-child {
    padding-bottom: 40px;
  }
}
.ProgramListItemCardFocus {
  background-color: rgba($white, 0.2);
}

.ProgramListItemCardInner {
  display: flex;

  height: 135px;
  width: 1880px;

  padding: 25px 30px;

  border-radius: 10px;
  overflow: hidden;

  //background-color: rgba($white, 0.2);

  @include font-styles('Roboto', 24px, 400, 120%);

  .Icon {
    width: 25px;
    min-width: 25px;
    margin-right: 65px;
  }

  .Time {
    width: 90px;
    min-width: 90px;
    margin-right: 65px;
  }

  .Title {
    width: 400px;
    min-width: 400px;
    margin-right: 65px;
    @include text-ellipsis(3);
  }

  .Description {
    width: calc(1880px - 400px - 90px);
    @include text-ellipsis(3);
  }

  &__Active {
    color: $accent;
  }
}
