@import "src/styles/mixins";

.Animated {
  @include flex-centered;

  height: 180px;
  width: 180px;

  border-radius: 50%;

  background-color: rgba(black, 0.6);

  position: absolute;

  pointer-events: none;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  opacity:0;
  font-size: 100px;

  i[class='icon-fill-play'] {
    margin-left: 14px;
  }
}

.Show {
  animation: animate 1s ease-in-out;

  @keyframes animate {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}