@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto/Roboto-Regular.eot');
  src: local('Roboto'), local('Roboto-Regular'),
  url('../assets/fonts/roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/roboto/Roboto-Regular.woff') format('woff'),
  url('../assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto/Roboto-Light.eot');
  src: local('Roboto'), local('Roboto-Light'),
  url('../assets/fonts/roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/roboto/Roboto-Light.woff') format('woff'),
  url('../assets/fonts/roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto/Roboto-Medium.eot');
  src: local('Roboto'), local('Roboto-Medium'),
  url('../assets/fonts/roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/roboto/Roboto-Medium.woff') format('woff'),
  url('../assets/fonts/roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto/Roboto-Bold.eot');
  src: local('Roboto'), local('Roboto-Bold'),
  url('../assets/fonts/roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/roboto/Roboto-Bold.woff') format('woff'),
  url('../assets/fonts/roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/montserrat/Montserrat-SemiBold.eot');
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
  url('../assets/fonts/montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/montserrat/Montserrat-SemiBold.woff') format('woff'),
  url('../assets/fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/montserrat/Montserrat-Regular.eot');
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
  url('../assets/fonts/montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/montserrat/Montserrat-Regular.woff') format('woff'),
  url('../assets/fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
