@import "src/styles";

.List {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.Card {
  width: 430px;
  
  &:last-of-type {
    margin-right: 0;
  }
}