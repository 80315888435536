@import "src/styles";

.ViewsHeader {
  height: 120px;
  margin-left: 170px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @include card-title-font-styles;
  font-size: 46px;
  padding: 0 40px 0 10px;

  font-weight: 500;

  .Title {
    max-width: 500px;
  }
}