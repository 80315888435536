@import "src/styles";

.DeletableCardWrapper {
  position: relative;
  padding: 15px 10px;
  width: 578px;

  &:last-of-type {
    margin-right: 0;
  }
}

.FocusWrapper {
  &__DeleteMode {
    padding: 0;
    border-color: transparent;
    box-shadow: none;
  }
}

@include default-delete-card-inner-styles;