@import "src/styles";


.ResetActive.ResetActive  {
  border-color: $active-reset-filters-background-color;
  color: $active-reset-filters-background-color;

  &:hover,&:active {
    background-color: $active-reset-filters-background-color;
    color: $white;
    box-shadow: $reset-button-active-box-shadow;
  }
}

.ResetFocused.ResetFocused  {
  background-color: $active-reset-filters-background-color !important;
  border-color: $active-reset-filters-background-color !important;
  color: $white;
  box-shadow: $reset-button-active-box-shadow !important;
}