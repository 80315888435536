.List {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.ListScrollWrapper {
  will-change: transform;
  transform: translateY(0);
  transition: transform 0.2s ease;
}