@import "src/styles/variables";
@import "src/styles/mixins";

.MenuButton.MenuButton {
  background-color: transparent;
  width: 170px;
  padding: 16px 0;

  z-index: 0;

  &__Focused, &__Active, &:hover {
    background-color: transparent !important;
    box-shadow: none !important;
    color: $accent;
  }

  &__Focused {
    color: #FFFFFF;

    &:before {
      content: none !important;
    }

    .FocusBG {
      opacity: 1 !important;
    }
  }

  .FocusBG {
    position: absolute;
    content: '';
    width: 360px;
    height: 100%;
    border-radius: 10px;
    background-color: rgba(61, 69, 97, 0.6);;
    left: -250%;
    z-index: -3;
    opacity: 0;
  }

  .MenuTitle {
    position: absolute;

    width: 300px;
    text-align: left;
    left: -200%;

    top: calc(50% - 12px);

    @include font-styles('Montserrat', 25px, 500, 1);

    @include text-ellipsis(1);

    z-index: -2;
  }

  &__Opened {
    &:before {
      background-color: $primary;
    }
  }

  &:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 60%;
    background-color: transparent;
    left: 0;
    top: 0;
    z-index: -1;
    transition: background-color 0s ease;
    transition-delay: 0.2s;
  }
}