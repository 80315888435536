@import "src/styles/variables";

.Sidebar {
  height: 1080px;
  display: flex;

  .Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 1080px;
    padding: 20px 0 20px 0;

    .FrameContainer {
      width: 100%;
      padding-right: 10px;
    }

    .ActionsContainer {
      width: 100%;
      display: flex;
      padding-right: 30px;
      flex-direction: column;
      justify-content: flex-end;

      &>div {
        margin-top: 10px;
      }
    }

    .ActionWrapper {
      width: 100%;
    }

    .Action {
      height: 70px;
      width: 100%;
    }

    .DeleteAction.DeleteAction {
      background-color: transparent;
      border: 3px solid $bg-with-opacity;
      box-shadow: none;

      &__Focused {
        border-color: $accent !important;
        color: $accent;
      }
    }
  }
}