@import "src/styles/mixins";

.Actions {
  @include  overlay-actions-list-styles;

  .Button {
    @include  overlay-action-styles;

    span[data-title='title'] {
      max-width: 550px;
      @include text-ellipsis(1);
      text-align: left;
    }
  }
}
