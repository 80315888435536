@import "src/styles/variables";

.User {
  margin: 20px 0 auto;
}

.Notification {
  margin: auto 0 0;
}

.MenuWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 170px;
  align-items: center;
  padding: 50px 0;
  background: linear-gradient(90deg, rgba($primary, 1) 0%, rgba($primary, 0) 100%);
}

.BG {
  position: absolute;
  width: 1920px;
  height: 1080px;
  top: 0;
  left: -1920px;
  background: $primary;
}

.BGCover {
  position: absolute;

  width: 860px;
  height: 1080px;

  background: linear-gradient(90deg, rgba($primary, 1) 0%, rgba($primary, 1) 50%, rgba($primary, 0) 100%);

  top: 0;
  left: -860px;
}
