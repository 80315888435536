.FocusWrapper {
  height: 100%;
}

.ScrollingWrapper {
  height: 100%;
  padding: 15px 0 15px 170px;
  overflow-x: auto;
  overflow-y: hidden;
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;

  &:first-child {
    display: flex;
  }
}

.ContentWrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 30px 20px 30px 0;
}
