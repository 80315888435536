@import "src/styles/variables";

.ColumnWrapper {
  height: 500px;
}

.ColumnScrollingWrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  &:first-child {
    will-change: transform;
    transition: transform 0.4s;
  }
}

.ContentWrapper {
  will-change: transform;
  transition: transform $vertical-scroll-duration;
}