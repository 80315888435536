@import "src/styles/variables";
@import "src/styles/mixins";

.DescriptionContainer {
  padding: 40px 0 0 190px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  .Title {
    @include content-title-font-styles;
    font-size: 58px;
    margin-bottom: 15px;
    line-height: 1.15;
    @include text-ellipsis(2);
  }

  .Partner {
    height: 40px;
    margin-bottom: 20px;
  }
  .Keywords {
    width: 750px;

    @include content-keywords-font-styles;

    @include text-ellipsis(1);

    margin-bottom: 20px;
  }

  .Icons {
    width: 750px;

    @include text-ellipsis(1);
    display: flex;
    height: 40px;
    margin-bottom: 20px;

    .Icon {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .Description {
    width: 700px;
    @include text-ellipsis(6);
    @include content-description-font-styles;
  }

  &[data-full='false'] {
    .Title {
      width: 850px;
    }
  }

  &[data-full='true'] {
    .Title {
      width: 1000px;
    }

    .Description {
      @include text-ellipsis(8);
    }
  }
}