@import "src/styles/variables";
@import "src/styles/mixins";

.FocusWrapper {
  position: relative;
  .FrameContainer__Scrollable {
    &::-webkit-scrollbar-thumb {
      background: $accent;
      border-radius: 10px;
      &:hover {
        background:  $accent;
      }
    }
  }
}

.FrameContainer {
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 20px;


  &__Scrollable {
    padding-right: 12px;

    &::-webkit-scrollbar {
      //should be there, because we set display none in index.scss
      display: unset;
      width: 8px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.20);
      border-radius: 10px;
      &:hover {
        background-color: rgba(255, 255, 255, 0.20);
      }
    }

    &::-webkit-scrollbar-thumb {
      background: gray;
      border-radius: 10px;
      &:hover {
        background:  gray;
      }
    }
  }

}

.ScrollingContainer {
  width: 100%;
  padding-bottom: 5px;
}

.Frame {
  border: none;
  outline: none;
  transition: opacity 1s linear;
  opacity: 1;
}

.Hidden {
  opacity:0;
}

.ScrollingContainer {
  width: 100%;
}

.TitleDescriptionContainer {
  .Title {
    @include font-styles('Roboto', 30px, 600, 120%);
    @include text-ellipsis(1);
    margin-bottom: 10px;
  }

  .Description {
    text-align: justify;
    @include font-styles('Roboto', 24px, 500, 120%);
    padding-bottom: 5px;
  }
}
