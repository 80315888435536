.SerialsPlayerPage {
  width: 1920px;
  height: 1080px;

  background-color: black;
}

.SerialsPlayer {

}

.SerialSubTitle {
  opacity: .6;
  font-size: 30px;
  margin-top: 5px;
}