@import "src/styles/mixins";
@import "src/styles/variables";

.Tv {
  width: 1920px;
  height: 1080px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__Footer {
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}