.LoadMoreButtonWrapper {
  margin-top: 10px;

  height: 200px;
  width: 1750px;

  position: relative;

  .SkeletonBody {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .SkeletonCard {
    height: 100%;
    width: calc(100%/4 );
    padding: 0 15px;
  }
}