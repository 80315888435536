@font-face {
  font-family: 'itv3-icons-fill';
  src:  url('../assets/fonts/icons/fill/itv3-icons-fill.eot?jp21xt');
  src:  url('../assets/fonts/icons/fill/itv3-icons-fill.eot?jp21xt#iefix') format('embedded-opentype'),
    url('../assets/fonts/icons/fill/itv3-icons-fill.ttf?jp21xt') format('truetype'),
    url('../assets/fonts/icons/fill/itv3-icons-fill.woff?jp21xt') format('woff'),
    url('../assets/fonts/icons/fill/itv3-icons-fill.svg?jp21xt#itv3-icons-fill') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-fill-"], [class*=" icon-fill-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'itv3-icons-fill' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-fill-sort:before {
  content: "\e930";
}
.icon-fill-back:before {
  content: "\e92b";
}
.icon-fill-keyboard-no:before {
  content: "\e92c";
}
.icon-fill-keyboard:before {
  content: "\e92d";
}
.icon-fill-pencil:before {
  content: "\e92e";
}
.icon-fill-shift:before {
  content: "\e92f";
}
.icon-fill-arrow-left:before {
  content: "\e900";
}
.icon-fill-arrow-recall:before {
  content: "\e901";
}
.icon-fill-arrow-right:before {
  content: "\e902";
}
.icon-fill-menu:before {
  content: "\e903";
}
.icon-fill-account:before {
  content: "\e904";
}
.icon-fill-archive:before {
  content: "\e905";
}
.icon-fill-at-first:before {
  content: "\e906";
}
.icon-fill-ball:before {
  content: "\e907";
}
.icon-fill-balloon:before {
  content: "\e908";
}
.icon-fill-catalog:before {
  content: "\e909";
}
.icon-fill-check:before {
  content: "\e90a";
}
.icon-fill-chevron-down:before {
  content: "\e90b";
}
.icon-fill-chevron-left:before {
  content: "\e90c";
}
.icon-fill-chevron-right:before {
  content: "\e90d";
}
.icon-fill-chevron-up:before {
  content: "\e90e";
}
.icon-fill-clock-back:before {
  content: "\e90f";
}
.icon-fill-close:before {
  content: "\e910";
}
.icon-fill-exit-fullscreen:before {
  content: "\e911";
}
.icon-fill-eye-close:before {
  content: "\e912";
}
.icon-fill-eye-open:before {
  content: "\e913";
}
.icon-fill-movie:before {
  content: "\e914";
}
.icon-fill-fullscreen:before {
  content: "\e915";
}
.icon-fill-group:before {
  content: "\e916";
}
.icon-fill-heart:before {
  content: "\e917";
}
.icon-fill-home:before {
  content: "\e918";
}
.icon-fill-bolt:before {
  content: "\e919";
}
.icon-fill-mark:before {
  content: "\e91a";
}
.icon-fill-next:before {
  content: "\e91b";
}
.icon-fill-notification:before {
  content: "\e91c";
}
.icon-fill-pause:before {
  content: "\e91d";
}
.icon-fill-play:before {
  content: "\e91e";
}
.icon-fill-play-circle:before {
  content: "\e91f";
}
.icon-fill-plus:before {
  content: "\e920";
}
.icon-fill-question:before {
  content: "\e921";
}
.icon-fill-search:before {
  content: "\e922";
}
.icon-fill-grid:before {
  content: "\e923";
}
.icon-fill-settings:before {
  content: "\e924";
}
.icon-fill-settings2:before {
  content: "\e925";
}
.icon-fill-sound-off:before {
  content: "\e926";
}
.icon-fill-sound-on:before {
  content: "\e927";
}
.icon-fill-star:before {
  content: "\e928";
}
.icon-fill-subtitles:before {
  content: "\e929";
}
.icon-fill-tv:before {
  content: "\e92a";
}
