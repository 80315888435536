@import "src/styles";
.ModalRenderContainer.ModalRenderContainer {
  display: flex;
  justify-content: center;

  min-height: 400px;
  height: fit-content;
  width: 1920px;


  padding: 0;
  opacity: 1;
  position: absolute;
  will-change: transform;
  transform: translateY(100%);
  transition: transform 0.4s ease-in-out;
  right: 0;
  bottom: 0;
}

.Background.Background {
  opacity: 1;
  background: linear-gradient(0deg, $primary 0%, $primary 35%, rgba($primary, 0) 100%);
}

.ActiveModalRenderContainer.ActiveModalRenderContainer {
  transform: translateY(0);
}

.UnActiveModalRenderContainer.UnActiveModalRenderContainer {
  transform: translateY(100%);
  transition-delay: 0s !important;
}