@import "src/styles";

.FavoritesChannels {
  height: 400px;
  //padding: 30px 0 0;

  .Title.Title {
    margin-left: 200px;
    margin-bottom: 10px;
  }

  .EditMode {
    height: 70px;
    margin-left: 170px;
    position: relative;
    font-weight: 500;

    .Checkbox {
      width: 550px;
    }
  }

  .Wrapper {
    height: 240px;
  }

  .List {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .ContentWrapper {
    &:last-child {
      padding-right: 30px;
    }
  }

  .EmptyFavoritesContainer {
    padding: 0 40px 0 190px;
    margin-top: 25px;

    .Empty {
      height: 240px;
    }
  }

  .Title {
    margin-left: 10px;
    @include card-title-font-styles;
    margin-bottom: 30px;
    font-size: 30px;
  }
}