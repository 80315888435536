@import "mixins/font-styles";
@import "mixins/flex";
@import "variables/colors";
@import "variables";

@mixin content-image-bg-styles {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: fit-content;
  z-index: -1;
  overflow: hidden;

  & > img {
    width: 100%;
  }
}

@mixin full-width-height-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

//player overlay styles

@mixin overlay-action-styles {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 30px 0 20px;
  align-items: center;
  justify-content: flex-start;
  height: 57px;
  width: 100%;
  margin-bottom: 10px;

  font-weight: 600;
  letter-spacing: 1px;
  box-shadow: none;

  span[data-title='title'] {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 400;

    span {
      margin-left: 5px;
      margin-top: 2px;
    }
  }

  i {
    display: flex;
    justify-content: center;
    font-size: 25px !important;
    margin-right: 15px;
  }

  &:hover {
    background: $accent;
    font-weight: 600;
  }
}

@mixin overlay-actions-list-styles {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  min-width: 370px;
}

// trailer styles

@mixin trailer-styles {
  position: fixed;

  z-index: -1;

  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;

  opacity: 0;
  transition: opacity 0.4s ease;
}

@mixin trailer-player-styles {
  top: -200px;
  height: calc(100% + 400px);
  width: calc(100% + 650px);
  left: -300px;;
}

@mixin trailer-overlay-styles {
  box-shadow: 0 0 800px 800px rgba($primary, 0.7) inset;
}

@mixin trailer-sub-title-styles {
  opacity: .6;
  font-size: 30px;
  margin-top: 5px;
}

// chosen content page styles

@mixin chosen-content-page-styles {
  display: flex;
  flex-direction: column;

  width: 1920px;
  height: 500px;

  overflow: hidden;

  &__Footer {
    width: 1920px;
    height: 500px;

    .AdditionalInfo {
      width: 700px;

      @include content-description-font-styles;
      @include text-ellipsis(3);

      margin-bottom: 30px;
    }

    .ActionsContainer {
      width: 100%;

      padding-bottom: 50px;

      display: flex;
      justify-content: flex-start;

      .ActionButton {
        height: 80px;
        min-width: 80px;
        padding: 20px 20px;
        white-space: nowrap;
      }

      & > :not(:last-child) {
        margin-right: 25px;
      }
    }

    .FocusWrapper {
      padding: 0;
      height: 500px;
    }

    .ActionsFocusWrapper {
      padding-left: 190px;
    }
  }
}

// search filters styles

@mixin default-checkbox-styles {
  .DefaultCheckbox {
    position: relative;
    margin-bottom: 38px;

    &:after {
      content: '';
      width: 100%;
      height: 2px;
      opacity: 0.2;
      background-color: #FFF;
      position: absolute;
      bottom: -20px;
    }
  }
}

@mixin default-filter-action-hovered-or-focused-styles {
  background-color: $bg-with-opacity !important;
  border-color: $accent !important;
  color: $white;
}

@mixin default-filter-action-styles {
  .FilterActive.FilterActive {
    &:after {
      position: absolute;
      content: '';
      background-color: $accent;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      left: 12px;
      top: 16px;
    }

    & > span:first-child {
      color: $accent;
      padding-left: 20px;
    }

    &:hover {
      @include default-filter-action-hovered-or-focused-styles;
    }
  }

  .WithoutPrepend.WithoutPrepend {
    &:after {
      content: none;
    }

    & > span:first-child {
      color: $accent;
      padding-left: 0;
    }
  }

  .FilterFocused.FilterFocused {
    @include default-filter-action-hovered-or-focused-styles;
  }
}

@mixin default-delete-card-inner-styles {
  .FocusWrapper {
    .DeleteButton__NotDeleting {
      background-color: $accent;
    }

    //.DeleteButton__Deleting {
    //  background-color: rgba($accent, 0.6);
    //}
  }

  .OpacityWrapper {
    opacity: 0.5;
  }

  .DeleteButton  {
    position: absolute;

    top: calc(50% - 15px);
    left: 50%;

    transform: translate(-50%, -50%);

    border-radius: 50%;

    font-size: 38px;

    height: 90px;
    width: 90px;

    @include flex-centered;

    background-color: $bg-with-opacity;

    &__NotDeleting {
      &:hover {
        background-color: $accent;
      }
    }


    &__Deleting {
      & > div {
        width: 100%;
        height: 100%;
        position: relative;

        img {
          width: 60%;
        }
      }
    }
  }
}

@mixin content-card-inner-styles {
  .ContentCardInner {
    width: 100%;

    display: flex;
    flex-direction: column;

    position: relative;

    .FourKLabel {
      position: absolute;

      top: 5px;
      right: 5px;

      width: 40px;

      &>img {
        width: 100%;
        object-fit: cover;
      }
    }

    .Image {
      width: 100%;
      height: 230px;
      @include flex-centered;
      background-color: $secondary;
      overflow: hidden;
      position: relative;

      img {
        position: absolute !important;
        width: 100%;
        object-fit: cover;
        filter: blur(8px);
        -webkit-filter: blur(8px);
      }

      img:last-child {
        position: relative;
        width: unset;
        height: 100%;
        object-fit: cover;
        filter: none;
        -webkit-filter: none;
      }

      div {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }
    }

    .ContentDescription {
      width: 100%;
      height: 95px;

      padding: 15px 20px;

      background-color: $secondary;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .Title {
        @include text-ellipsis(1);
        @include font-styles('Roboto', 28px, 500, 100%);
        letter-spacing: -0.28px;

        div {
          height: 28px;
        }
      }

      .Description {
        @include content-description-font-styles;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-height: 30px;

        color: #8e9498;

        &__Text {
          //flex-grow: 1;
          width: 250px;
          @include text-ellipsis(1);
        }

        &__PartnerLogo {
          width: 100px;
          display: flex;
          justify-content: flex-end;

          img {
            height: 100%;
            max-width: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}