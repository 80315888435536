@import "src/styles/mixins";

.Actions {
}

.CircleButtonAction.CircleButtonAction {
  background-color: rgba(0, 0, 0, 0.6);
}

.Settings {
  width: 450px;
  height: 1080px;
  padding-right: 50px;

  h4 {
    height: 135px;
    padding: 50px 0 40px 20px;
    @include font-styles('Montserrat', 36px, 600, 125%);
  }

  .List {
    width: 100%;
    height: 945px;
  }

  .ActionButton.ActionButton {
    width: 400px;
    height: 100px;
    background-color: transparent;
    box-shadow: none;

    &:hover {
      background-color: rgba(61, 69, 97, 0.6);
    }

    &__Focused {
      background-color: rgba(61, 69, 97, 0.6);
    }

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding-left: 20px;

    span {
      @include font-styles('Montserrat', 24px, 600, 100%);
      letter-spacing: -0.24;
    }

    span:last-child {
      margin-top: 16px;
      @include font-styles('Roboto', 24px, 400, 100%);
      color: #929090;
    }

    &:before {
      position: absolute;
      content: "\e90d";
      font-family: 'itv3-icons-line';
      right: 20px;
      font-size: 20px;
    }
  }
}

.BgTransparent.BgTransparent {
  opacity: 1;
  background: linear-gradient(270deg, $primary 0%, $primary 450px, rgba($primary, 0) 600px, rgba($primary, 0) 100%);
}