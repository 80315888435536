.FavoritesAction {

  .Icon {
    &__Active {
      color: red;
    }
  }
  //
  //.Text {
  //  margin-left: 10px;
  //}
}