@import "src/styles/variables";
@import "src/styles/mixins";

.ChosenContent {
  width: 100%;
  height: 100%;

  &__BgContainer {
    @include full-width-height-absolute;

    z-index: -1;

    display: flex;
    justify-content: flex-end;

    &__Overlay {
      position: fixed;

      width: 100%;
      height: 100%;

      z-index: 1000;

      box-shadow: 800px -350px 150px 100px rgba($primary, 1) inset;
      opacity: 1;

      &__Background {
        opacity: 0.6;
        box-shadow: 0 0 150px 700px rgba($primary, 1) inset;
      }
    }

    .Image {
      position: fixed !important;

      right: 0;
      top: 0;

      width: 1420px;
      height: 800px;

      opacity: 1;

      background-size: contain;
      background-repeat: no-repeat;
      background-position: top right;
    }
  }
}
