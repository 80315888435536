@import 'src/styles/variables';
@import 'src/styles/mixins';

.Notifications {
  width: 100%;
  height: 100%;
}

.Empty {
  width: 850px;
  @include font-styles('Montserrat',  24px, 600, normal );
}

.List {
  height: calc(100% - 110px);
  width: calc(100% - 30px);
}

