@import "src/styles/mixins";
@import "src/styles/variables";

.ProgramListModal {
  width: 1860px;
  height: 1050px;

  .Header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 120px;
    padding-bottom: 40px;

    .Title {
      align-items: center;
      margin-left: 30px;
      width: calc(100% - 100px);
      @include font-styles('Montserrat', 36px, 600, normal);
      @include text-ellipsis(1);
    }
  }

  .TabsContainer {
    width: 100%;
    height: 930px;
  }
}
