// Color system

// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

// scss-docs-start gray-colors-map
$grays: (
        '100': $gray-100,
        '200': $gray-200,
        '300': $gray-300,
        '400': $gray-400,
        '500': $gray-500,
        '600': $gray-600,
        '700': $gray-700,
        '800': $gray-800,
        '900': $gray-900,
) !default;
// scss-docs-end gray-colors-map

// scss-docs-start theme-color-variables
//$primary: #091649 !default;
$primary: #03112E !default;
$secondary: #100F23 !default;
$accent: #01afe2 !default;
$success: #4caf50 !default;
$info: #2196f3 !default;
$warning: #ff9800 !default;
$danger: #f44336 !default;
$white: #fff !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;
$black: #000 !default;
// scss-docs-end theme-color-variables

// Customize the light and dark text colors for use in our color contrast function.
$min-contrast-ratio: 1.5 !default;
$color-contrast-dark: $black !default;
$color-contrast-light: $white !default;

// scss-docs-start theme-colors-map
$theme-colors: (
        'primary': $primary,
        'secondary': $secondary,
        'accent': $accent,
        'success': $success,
        'info': $info,
        'warning': $warning,
        'danger': $danger,
        'light': $light,
        'dark': $dark,
) !default;
// scss-docs-end theme-colors-map

// scss-docs-start theme-text-variables
$primary-text: $primary !default;
$secondary-text: $secondary !default;
$accent-text: $accent !default;
$success-text: $success !default;
$info-text: $info !default;
$warning-text: $warning !default;
$danger-text: $danger !default;
$light-text: $light !default;
$dark-text: $dark !default;
// scss-docs-end theme-text-variables

// scss-docs-start theme-search-actions-variables
//$active-filter-background-color: $success !default;
$active-filter-background-color:#009688 !default;
$active-reset-filters-background-color: $danger !default;
// scss-docs-start theme-search-actions-variables