@import "src/styles/mixins";

.ErrorBoundary {
  width: 1920px;
  height: 1080px;
  @include flex-centered;
}

.ErrorContent {
  display: flex;
  flex-direction: column;
  align-items: center;

  hgroup {
    text-align: center;
    h1 {
      margin-bottom: 30px;
      @include font-styles('Roboto',  50px, 600, 120% );
      letter-spacing: -0.28px;
    }

    p {
      margin-bottom: 20px;
      @include font-styles('Roboto', 28px, 500, 120%);

      pre {
        text-align: left;
      }
    }

    margin-bottom: 50px;
  }
}

.ErrorButton {
  height: 80px;
  padding: 0 30px;
}