@import "src/styles";

.List {
  height: 100%;
  width: 100%;

  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    //should be there, because we set display none in index.scss
    display: unset;
    width: 8px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.20);
    border-radius: 10px;
    margin: 15px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.20);
    }
  }

  &::-webkit-scrollbar-thumb {
    background: $accent;
    border-radius: 10px;
    &:hover {
      background:  $accent;
    }
  }
}