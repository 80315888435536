@import "src/styles/variables";
@import "src/styles/mixins";

.CardInner {
  height: 40px;
  padding: 0 40px;

  @include flex-centered;
  background-color: $secondary;

  @include font-styles('Montserrat', 24px, 600, 1);

  white-space: nowrap;
}

.Focused {
  background-color: #1C2842FF;
}

.Active {
  color: $accent;
  position: relative;

  &:before {
    position: absolute;

    content: '';

    width: 10px;
    height: 10px;

    border-radius: 50%;

    background-color: $accent;

    left: 20px;
    top: calc(50% - 4px)
  }
}