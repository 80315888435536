@import "src/styles/variables";
@import "src/styles/mixins";

.SessionCardWrapper {
  padding: 10px 10px;
  width: 500px;
}

.Inner {
  --opacity: 0.4;

  height: 100px;

  padding: 0 36px;

  display: flex;
  align-items: center;

  background-color: $bg-with-opacity;

  &__Disabled {
    background-color: rgba($bg-with-opacity, var(--opacity));
  }

  .DeviceInfo {
    height: 100%;
    padding: 20px 0 15px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .Title {
    @include font-styles('Montserrat', 32px, 600, 100%);
    @include text-ellipsis(1);

    &__Disabled {
      opacity: var(--opacity);
    }
  }

  .AdditionalInfo {
    @include font-styles('Montserrat', 24px, 600, 100%);
    @include text-ellipsis(1);
    color: rgba(white, 0.6);

    &__Disabled {
      opacity: var(--opacity);
    }
  }

  .DeleteIcon {
    padding-top: 2px;
    font-size: 28px;

    &__Disabled {
      opacity: var(--opacity);
    }
  }
}