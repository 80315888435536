@import "src/styles/variables";
@import "src/styles/mixins";

.NeedPackages {
  width: 1750px;
  height: 1050px;

  .Title {
    @include content-title-font-styles;
    @include text-ellipsis(1);

    height: 140px;
    width: 100%;
  }

  .List {
    height: 910px;
    width: 100%;
  }

  .ListScrollWrapper {
    display: flex;
    flex-wrap: wrap;
  }
}
