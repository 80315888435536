@import "src/styles";

.Input {
  outline: none;

  border: 2px solid transparent;
  border-radius: 10px;

  height: 80px;
  width: 100%;

  padding: 0 20px;

  @include font-styles('Roboto', 28px, 500, 120%);
  letter-spacing: -0.28px;

  background: rgba(255, 255, 255, 0.20);
  color: #FFFFFF;

  &:disabled {
    background: rgba(255, 255, 255, 0.20);
    color: #FFFFFF;
  }

  &::placeholder {
    color: rgba( #FFFFFF, 0.4);
    @include font-styles('Roboto', 28px, 500, 120%);
    letter-spacing: -0.28px;
  }
}

.Focused.Focused {
  border-color: $accent;
  box-shadow: $button-active-box-shadow;
}