@import "src/styles/mixins";

.Overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
  overflow: hidden;

  z-index: 100000000000;

  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.Show.Show {
  opacity: 1;
}

.Active.Active {
  transition-delay: 0.2s;
}

.CloseButton {
  margin-right: 30px;
}

.Background {
  @include full-width-height-absolute;

  background-color: black;

  opacity: 0;
  transition: opacity 0.4s ease-in-out;

  z-index: -1;

  &__Active {
    opacity: 0.9;
  }
}

.RenderContainer {
  display: flex;

  width: 100%;
  height: 100%;

  padding: 30px 30px 0;

  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
