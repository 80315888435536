@import "src/styles/variables";
@import "src/styles/mixins";

.ShortProgramList {
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 20px;
  }

  & > div:last-child {
    margin-bottom: 0;
  }
}

.Item {
  background: linear-gradient(270deg, $primary 0%, $primary 10%, rgba($accent, 0.3) 100%);
  border-radius: 10px;
  padding: 10px 0 10px 20px;

  .Title {
    width: 100%;
    height: 40px;
    @include font-styles('Roboto', 34px, 500, normal);
    @include text-ellipsis(1);

    margin-bottom: 15px;
  }

  //.Year {
  //  width: 100%;
  //
  //  @include content-keywords-font-styles;
  //
  //  @include text-ellipsis(1);
  //
  //  margin-bottom: 20px;
  //}

  .Time {
    height: 33px;
    width: 100%;
    @include font-styles('Roboto', 28px, 500, normal);
    margin-bottom: 20px;
  }

  .Description {
    height: 94px;
    width: 100%;
    @include text-ellipsis(3);
    @include content-description-font-styles;
  }
}