@import "src/styles/variables";

.SearchInput.SearchInput {
  position: relative;
  height: 130px !important;
  min-height: 130px !important;
  width: 1735px;

  padding: 30px 30px 25px 10px;

  .Input.Input {
    width: 100%;
    height: 100%;
    background-color: #354158;

    color: white;
  }

  .KeyboardIcon {
    position: absolute;

    font-size: 44px;
    right: 50px;
    top: 47px;

    color: #9d9d9d;
  }

  .Active {
    color: $accent;
  }
}

.RenderContainer {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-between;

  height: 430px;
  padding-bottom: 30px;
}

.CloseButton.CloseButton {
  background-color: transparent;
  font-size: 30px;

  &:hover {
    background-color: transparent !important;
    box-shadow: none !important;
    color: $accent;
  }
}

.FocusedCloseButton.FocusedCloseButton {
  background-color: transparent !important;
  box-shadow: none !important;
  color: $accent;
}

.Keyboard.Keyboard {
  margin-left: 0;
}