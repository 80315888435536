@import "src/styles";

.Filters {
  height: 130px;
  //margin-left: 180px;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  & > div {
    width: calc((100% - 75px - 6 * 24px) / 6);

    &:last-child {
      width: 75px;
    }
  }

  .FilterButton {
    height: 75px;
    width: 100%;

    border: 3px solid transparent;

    padding: 0 15px;

    display: flex;
    flex-direction: column;

    &:hover {
      @include default-filter-action-hovered-or-focused-styles;
    }

    & > span {
      //width: 100%;
      width: 206px; // for chromium 53 (lg 18-19 year (webos 4))
      font-size: 26px;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.32px;
      @include text-ellipsis(1);
      text-align: left;
    }

    & > span:first-child {
      position: relative;

      &:before {
        content: "\e90b";
        font-family: 'itv3-icons-line';
        font-size: 20px;
        position: absolute;
        top: 2px;
        right: 0;
      }
    }

    & > span:last-child {
      font-size: 22px;
      color: rgba(white, 0.6);
    }

    &__Reset {
      background: transparent;
      border: 3px solid #26304c;

      &:hover {
        border-color: $active-reset-filters-background-color !important;
        background-color: $active-reset-filters-background-color !important;
      }

      &:disabled {
        &:hover {
          border-color: #26304c !important;
          background-color: transparent !important;
          color: rgba(white, 0.5);
        }
      }

      @include flex-centered;

      position: relative;

      &:before {
        content: "\e910";
        font-family: 'itv3-icons-line';
        font-size: 28px;
        position: absolute;
      }
    }

    &__Sort {
      span {
        padding-left: 40px !important;
      }

      & > span:first-child {
        position: relative;
        overflow: unset;

        &:before {
          content: "\e932";
          font-family: 'itv3-icons-line';
          font-size: 24px;
          position: absolute;
          top: 12px;
          left: 0;
        }
      }
    }
  }
}