@import "variables";
@import "mixins";
@import "fonts";
@import "icons.line";
@import "icons.fill";


html,
body {
  padding: 0;
  margin: 0;
  font-family: Roboto, sans-serif;
  background-color: $primary;
  color: #fff;
  font-size: 14px;
  box-sizing: border-box;
}

html, body {
  width: 1920px;
  height: 1080px;
  //overflow: hidden;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  outline: none;
}

img {
  display: block;
  border-style: none;
}

h1,h2,h3,h4,h5,h6, p {
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar {
  display: none;
}
