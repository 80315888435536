@import "src/styles/variables";
@import "src/styles/mixins";

.ContentCardWrapper {
  padding: 15px 10px;
  width: 410px;

  &:last-of-type {
    margin-right: 20px;
  }
}

.ContentCardFocusWrapper {
}

@include content-card-inner-styles;
