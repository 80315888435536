@import 'src/styles/variables';

$skeleton-bg: rgba($white, 0.15);
$loader-color: #fff;
$rotate-angle: 20deg;

.SkeletonView {
  position: relative;

  width: 100%;
  height: 100%;

  border-radius: 10px;

  background-color: $skeleton-bg;
  overflow: hidden;
  //opacity: 0.5;

  &__Rounded {
    border-radius: 50%;
  }

  span {
    opacity: 0;
  }

  &__WithAnimation {
    &:before {
      position: absolute;

      content: '';

      width: 10%;
      height: 100%;
      background-color: $loader-color;

      filter: blur(20px);

      transform: rotate($rotate-angle) scaleY(1.5);
      animation: skeleton-loading 1.5s linear infinite;
    }

    @keyframes skeleton-loading {
      0% {
        left: 0;
        background-color: rgba($loader-color, 0.4);
      }
      50% {
        background-color: rgba($loader-color,8);
      }
      100% {
        left: 100%;
        background-color: rgba($loader-color, 0.4);
      }
    }
  }
}
