@import "src/styles/variables";
@import "src/styles/mixins";

.Background.Background {
  opacity: 1;
  background: linear-gradient(270deg, #03112E 0%, #03112E 32%, rgba(3, 17, 46, 0.8) 60%, rgba(3, 17, 46, 0.6) 80%, rgba(3, 17, 46, 0.4) 95%, rgba(3, 17, 46, 0) 100%);
}

.ChannelsList {
  padding: 0 20px 0 0;
  height: 980px;

  &::-webkit-scrollbar-track {
    margin: 0 15px 15px;
  }
}

.FocusWrapper {
  padding: 0;
  overflow: unset;

  &>div {
    box-shadow: none;
  }

  &:last-child {
    margin-bottom: 20px;
  }
}

.Inner {
  min-width: 400px;
  height: 70px;

  display: flex;
  align-items: center;
  padding: 0 20px;

  background-color: $bg-with-opacity;

  @include font-styles('Roboto', 24px, 400, 120%);

  &__Focused {
    background-color: $accent;
    color: white !important;
  }

  &__Chosen {
    color: #01afe2;
  }
}

.ChannelIndex {
  text-align: center;
  width: 40px;
  margin-right: 20px;
}

.ChannelLogo {
  position: relative;
  margin-right: 20px;

  width: 50px;
  outline: none;
  overflow: hidden;
  border-radius: 5px;

  @include flex-centered;

  background-color: #fff;

  img {
    height: 50px;
    object-fit: contain;
  }
}
