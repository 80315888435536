@import "src/styles/variables";

.TvPlayer {
  width: 100%;
  height: 100%;
  position: relative;

  border: 4px solid transparent;

  &__Focused {
    border-color: $accent;
  }

  &__Fullscreen {
    border: none;

    position: fixed !important;
    top: 0;
    left: 0;

    z-index: 1;
  }

  .Player {
    background-color: transparent;
  }
}

.ChannelSubTitle {
  opacity: .6;
  font-size: 30px;
  margin-top: 5px;
}