@import "src/styles/mixins";

.Trailer {
  @include trailer-styles;
}

.TrailerPlayer {
  @include trailer-player-styles;
}

.Overlay {
  @include trailer-overlay-styles;
}

.Show {
  opacity: 1;
}

.MovieSubTitle {
  @include trailer-sub-title-styles;
}
