@import "src/styles/variables";
@import "src/styles/mixins";

.SeasonOrEpisodeSelector {
  display: flex;
  flex-direction: column;

  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .Header {
    display: flex;
  }

  .Title {
    @include content-title-font-styles;
    @include text-ellipsis(1);

    height: 140px;
    padding-left: 30px;
  }

  &__List {
    display: flex;
    flex-grow: 1;
    padding-left: 110px;
  }
}