@import "src/styles";

.ActiveViews {
  width: 1920px;
  height: 1080px;
  position: relative;

  display: flex;
  flex-direction: column;

  .EditMode {
    height: 70px;
    margin-left: 160px;
    position: relative;
    font-weight: 500;

    .Checkbox {
      width: 550px;
    }
  }

  .ListWrapper {
    width: 1920px;
    height: 890px;
    overflow: hidden;

    padding-left: 165px;
    padding-right: 10px;

    &__Empty {
      @include flex-centered;
      flex-direction: column;
      background-color: $secondary;
      width: 1720px;
      height: 850px;
      border-radius: 10px;
      margin-left: 170px;
      padding-left: 0;
    }
  }

  .Icon {
    margin-top: -100px;
    width: 240px;
    height: 240px;
  }

  .EmptyText {
    padding-bottom: 50px;

    text-align: left;

    @include card-title-font-styles;
    font-size: 28px;
    font-weight: 400;
  }

  .ToMainAction {
    height: 80px;
    padding: 0 30px;
  }

  .List {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .ContentWrapper {
    display: flex;
    flex-wrap: wrap;
  }
}