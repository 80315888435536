@import 'variables/colors';
@import 'variables/z-indexes';

// Customize the light and dark text colors for use in our color contrast function.
$min-contrast-ratio: 1.5 !default;
$color-contrast-dark: $black !default;
$color-contrast-light: $white !default;

// Options
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-transitions: true !default;
$enable-reduced-motion: true !default;
$enable-important-utilities: true !default;

// Spacing

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: $spacer * 0.25,
        2: $spacer * 0.5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 6,
) !default;
// scss-docs-end spacer-variables-maps

// Body

$body-color: $white !default;
$body-bg: $primary !default;

// Position

// scss-docs-start position-map
$position-values: (
        0: 0,
        50: 50%,
        100: 100%,
) !default;
// scss-docs-end position-map

// Typography
$font-family-base: sans-serif !default;

$font-size-root: null !default;
$font-size-base: 1rem !default;
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.25 !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 2 !default;

// Links

$link-color: $white !default;
$link-decoration: none !default;
$link-hover-color: $accent !default;
$link-hover-decoration: none !default;

// Z-index master list

// scss-docs-start zindex-stack
$zindex-dropdown: 1000 !default;
$zindex-modal-backdrop: 1050 !default;
$zindex-modal: 1055 !default;
$zindex-popover: 1070 !default;
$zindex-tooltip: 1080 !default;
$zindex-toast: 1090 !default;
// scss-docs-end zindex-stack

// scss-docs-start zindex-levels-map
$zindex-levels: (
        n1: -1,
        0: 0,
        1: 1,
        2: 2,
        3: 3,
) !default;
// scss-docs-end zindex-levels-map

$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;

// scss-docs-start collapse-transition
$transition-collapse: height 0.35s ease !default;
$transition-collapse-width: width 0.35s ease !default;
// scss-docs-end collapse-transition

// scss-docs-start border-variables
$border-width: 1px !default;
$border-widths: (
        1: 1px,
        2: 2px,
        3: 3px,
        4: 4px,
        5: 5px,
) !default;
$border-style: solid !default;
$border-color: $gray-300 !default;
$border-color-translucent: rgba($black, 0.175) !default;

$border-radius: 10px !default;
// scss-docs-end border-variables

// scss-docs-start box-shadow-variables
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;

// scss-docs-end box-shadow-variables

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
) !default;
// scss-docs-end grid-breakpoints

// scss-docs-start caret-variables
$caret-width: 0.3em !default;
$caret-vertical-align: $caret-width * 0.85 !default;
$caret-spacing: $caret-width * 0.85 !default;
// scss-docs-end caret-variables

// scss-docs-start box-shadow-variables
$box-shadow: 0 0.5rem 1rem rgba(var(--black), 0.15) !default;
$box-shadow-sm: 0 0.125rem 0.25rem rgba(var(--black), 0.075) !default;
$box-shadow-lg: 0 1rem 3rem rgba(var(--black), 0.175) !default;
$box-shadow-inset: inset 0 1px 2px rgba(var(--black), 0.075) !default;
// scss-docs-end box-shadow-variables

// button box-shadow default
$button-active-box-shadow: 0 0 14px $accent !default;
$reset-button-active-box-shadow: 0 0 14px $danger !default;
$input-active-box-shadow: 0 0 14px $accent !default;
//

// auth content linear gradient bg
$bg-with-opacity: rgba(#3d4561, 0.6);
$linear-gradient-bg: linear-gradient(to right, rgba($primary, 1) 0%, rgba($primary, 0.8) 100%) !default;
$content-description-linear-gradient-bg: linear-gradient(to right, rgba($primary, 1) 0%, rgba($primary, 1) 50%, rgba($primary, 0.2) 70%, rgba($primary, 0) 80%, rgba($primary, 0) 100%) !default;

// scroll time
$horizontal-scroll-duration: 0.6s !default;
$vertical-scroll-duration: 0.6s !default;
