@import "src/styles";

.EmptyFavorites {
  width: 100%;
  background-color: #100F23;

  padding: 0 100px;
  min-height: 200px;
  border-radius: 10px;

  @include flex-centered;

  //@include font-styles(Roboto, 28px, 500, 120%, normal);

  @include card-title-font-styles;
  font-size: 28px;
  font-weight: 400;

  text-align: center;
  //font-size: 26px;
}