@import "src/styles/variables";
@import "src/styles/mixins";

.Background.Background {
  background-color: transparent;
}

.Overlay.Overlay {
  width: unset;
  height: unset;
  left: unset;
  top: 30px;
  right: 30px;
  border-radius: 15px;
}

.RenderContainer.RenderContainer {
  height: unset;
  padding: 0;
}

.ValueContainer {
  display: flex;
  justify-content: flex-end;

  margin: 20px 40px;

  height: 50px;
  width: 100%;
}

.Value {
  @include flex-centered;

  text-align: center;
  height: 100%;
  width: 150px;

  background-color: $secondary;
  border-radius: 10px;

  @include font-styles('Roboto', 32px, 700, 100%);
}