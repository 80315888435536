@import "src/styles/variables";
@import "src/styles/mixins";

$dark-color: #212529;

.Alert {
  display: flex;

  width: 800px;

  margin: 10px;
  padding: 10px 15px;

  border-radius: 10px;
  overflow: hidden;

  background-color: $dark-color;
  color: white;

  &__Icon {
    max-width: 40px;
    @include flex-centered;
    margin-right: 15px;
  }

  &__Content {
    display: flex;
    flex-direction: column;

    &__Title {
      position: relative;
      @include font-styles('Roboto', 30px, 600, 120%);
      @include text-ellipsis(1);
      margin-bottom: 10px;

      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        bottom: 0;
        background-color: #fff;
      }
    }

    &__Message {
      @include font-styles('Roboto', 24px, 600, 120%);
    }
  }

  &__Light {
    background-color: $gray-400;
    color: $dark-color;
  }

  &__Colored {
    color: white;
  }

  &__Colored[data-types = 'info'] {
    background-color: $info;
  }

  &__Colored[data-types = 'success'] {
    background-color: $success;
  }

  &__Colored[data-types = 'warning'] {
    background-color: $warning;
  }

  &__Colored[data-types = 'error'] {
    background-color: $danger;
  }

  svg[data-type = 'info'] {
    fill: $info;
  }

  svg[data-type = 'success'] {
    fill: $success;
  }

  svg[data-type = 'warning'] {
    fill: $warning;
  }

  svg[data-type = 'error'] {
    fill: $danger;
  }

  svg[data-type = 'default'] {
    fill: white;
  }
}