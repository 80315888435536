@import "src/styles/mixins";
@import "src/styles/variables";

.ContentPlayerTitle {
  position: relative;
  color: white;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 1px;
  width: 100%;
  text-align: right;

  display: flex;
  flex-direction: column;
  justify-content: center;
}