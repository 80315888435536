.SkeletonWrapper {
  width: 100%;
  height: 100%;

  overflow: hidden;

  padding-left: 190px;

  .ActorsSkeletonWrapper {
    height: 125px;
    width: 700px;

    padding-bottom: 30px;

    .Title {
      width: 200px;
      height: 30px;

      margin-bottom: 5px;
    }

    .Actors {
      height: 60px;
      width: 100%;
    }
  }

  .GenresSkeletonWrapper {
    height: 95px;
    width: 700px;

    padding-bottom: 30px;

    .Title {
      width: 150px;
      height: 30px;

      margin-bottom: 5px;
    }

    .Genres {
      height: 35px;
      width: 100%;
    }
  }

  .ActionsSkeletonWrapper {
    height: 130px;

    padding-bottom: 50px;

    display: flex;

    &>div {
      width: 273px;
      margin-right: 25px;

      &:last-child {
        width: 80px;
      }
    }
  }

  .GenreSliderSkeletonWrapper {
    width: 100%;
    height: 400px;

    .GenreTitle {
      height: 50px;
      width: 300px;
      padding-bottom: 15px;
    }

    .GenreCards {
      width: 100%;
      height: calc(100% - 50px);
      display: flex;
      justify-content: space-between;

      .GenreCard {
        height: 100%;
        width: calc(100%/4 - 15px);
      }

    }
  }
}