@import "src/styles";

.Results {
  height: 792px;
  width: 100%;
  display: flex;

  &__Empty {
    display: flex;
    align-items: center;
    justify-content: center;

    @include card-title-font-styles;
    font-size: 36px;
    font-weight: 400;
  }
}