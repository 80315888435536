.TvChosenChannel {
  width: 100%;

  display: flex;
  justify-content: space-between;

  padding: 30px 30px 30px 0;

  .PlayerContainer {
    position: relative;
    width: 918px;
    min-width: 918px;
    max-width: 918px;
    height: 520px; // 580 - 30 - 30 -8
  }
}