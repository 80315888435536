.Logo {
  width: 60px;
  height: 60px;
  position: relative;

  z-index: 1;

  img {
    width: 100%;
    object-fit: cover;
  }

  .Hat {
    width: 100%;
    position: absolute;
    top: -30%;
    left: 10px;

    img {
      width: 100%;
      object-fit: cover;
    }
  }
}