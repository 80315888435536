@import "src/styles";

.Notifications {
  position: relative;

  &__Active {
    &:before {
      position: absolute;

      top: 13px;
      left: 86px;

      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: red;
      z-index: 10;

      animation: loop 2s 0s ease infinite;
    }

    @keyframes loop {
      0% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, .6);
      }

      100% {
        box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
      }
    }
  }
}