@import "src/styles/variables";
@import "src/styles/mixins";

.List.List {
  width: unset;
  height: 910px;
  padding-top: 7px;
}

.SeasonButtonFocusWrapper {
  padding: 8px 0 8px 15px;

  &:last-child {
    padding-bottom: 50px;
  }
}

.SeasonButton {
  width: 230px;
  height: 80px;
  margin-right: 100px;
  @include font-styles('Roboto', 34px, 400, 120%);
}

.Active.Active {
  background-color: rgba($accent, 0.6);
}

.Focused {
  background-color: $accent;
}
