@import "src/styles/mixins";

.NotificationsLayout {
  width: 1920px;
  height: 1080px;

  padding-left: 180px;
}

.Title {
  height: 120px;
  @include font-styles('Montserrat', 36px, 600, 100%);
  padding: 40px 0;
}

.Content {
  height: 960px;
  width: 1740px;

  overflow: hidden;
}