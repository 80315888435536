@import "src/styles/variables";

.Time {
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  color: $white;
  font-family: Roboto, sans-serif;
}

.TimelineInformation {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 3px 8px;
}

.TimelineTooltip {
  position: absolute;
  padding: 3px 8px;
  transform: translateX(-50%);
  top: -50px;
  color: $white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  font-family: Roboto, sans-serif;
  font-size: 20px;
}