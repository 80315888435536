@font-face {
  font-family: 'itv3-icons-line';
  src:  url('../assets/fonts/icons/line/itv3-icons-line.eot?dqh9yc');
  src:  url('../assets/fonts/icons/line/itv3-icons-line.eot?dqh9yc#iefix') format('embedded-opentype'),
    url('../assets/fonts/icons/line/itv3-icons-line.ttf?dqh9yc') format('truetype'),
    url('../assets/fonts/icons/line/itv3-icons-line.woff?dqh9yc') format('woff'),
    url('../assets/fonts/icons/line/itv3-icons-line.svg?dqh9yc#itv3-icons-line') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-line-"], [class*=" icon-line-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'itv3-icons-line' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-line-sort:before {
  content: "\e932";
}
.icon-line-back:before {
  content: "\e92b";
}
.icon-line-pencil:before {
  content: "\e92c";
}
.icon-line-shift:before {
  content: "\e92d";
}
.icon-line-keyboard-no:before {
  content: "\e92e";
}
.icon-line-keyboard:before {
  content: "\e92f";
}
.icon-line-bolt:before {
  content: "\e902";
}
.icon-line-account:before {
  content: "\e901";
}
.icon-line-archive:before {
  content: "\e900";
}
.icon-line-arrow-left:before {
  content: "\e903";
}
.icon-line-arrow-recall:before {
  content: "\e904";
}
.icon-line-arrow-right:before {
  content: "\e905";
}
.icon-line-refresh:before {
  content: "\e906";
}
.icon-line-ball:before {
  content: "\e907";
}
.icon-line-balloon:before {
  content: "\e908";
}
.icon-line-catalog:before {
  content: "\e909";
}
.icon-line-check:before {
  content: "\e90a";
}
.icon-line-chevron-down:before {
  content: "\e90b";
}
.icon-line-chevron-left:before {
  content: "\e90c";
}
.icon-line-chevron-right:before {
  content: "\e90d";
}
.icon-line-chevron-up:before {
  content: "\e90e";
}
.icon-line-clock-back:before {
  content: "\e90f";
}
.icon-line-close:before {
  content: "\e910";
}
.icon-line-exit-fullscreen:before {
  content: "\e911";
}
.icon-line-eye-close:before {
  content: "\e912";
}
.icon-line-eye-open:before {
  content: "\e913";
}
.icon-line-movie:before {
  content: "\e914";
}
.icon-line-fullscreen:before {
  content: "\e915";
}
.icon-line-group:before {
  content: "\e916";
}
.icon-line-heart:before {
  content: "\e917";
}
.icon-line-home:before {
  content: "\e918";
}
.icon-line-mark:before {
  content: "\e919";
}
.icon-line-menu:before {
  content: "\e91a";
}
.icon-line-next:before {
  content: "\e91b";
}
.icon-line-notification:before {
  content: "\e91c";
}
.icon-line-pause:before {
  content: "\e91d";
}
.icon-line-play-circle:before {
  content: "\e91e";
}
.icon-line-play:before {
  content: "\e91f";
}
.icon-line-plus:before {
  content: "\e920";
}
.icon-line-question:before {
  content: "\e921";
}
.icon-line-search:before {
  content: "\e922";
}
.icon-line-grid:before {
  content: "\e923";
}
.icon-line-settings:before {
  content: "\e924";
}
.icon-line-settings2:before {
  content: "\e925";
}
.icon-line-sound-off:before {
  content: "\e926";
}
.icon-line-sound-on:before {
  content: "\e927";
}
.icon-line-star:before {
  content: "\e928";
}
.icon-line-subtitles:before {
  content: "\e929";
}
.icon-line-tv:before {
  content: "\e92a";
}
