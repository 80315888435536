@import "src/styles/variables";
@import "src/styles/mixins";

.ProgramContainer {
  margin: 4px 30px 4px 190px;
  flex-grow: 1;


  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ChannelTitle {
    display: flex;
    align-items: center;

    height: 90px;
    width: 100%;

    .ChannelLogo {
      height: 100%;
      min-width: 90px;
      margin-right: 20px;
      position: relative;
      border-radius: 5px;
      overflow: hidden;

      & > img {
        height: 100%;

        background-color: #fff;
      }
    }

    h1 {
      @include font-styles('Montserrat', 36px, 600, normal);
      @include text-ellipsis(1);
    }

  }

  .Actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;

    .ActionButton {
      height: 90px;
      //width: 625px;
      width: 525px;


      &__Icon {
        width: 100px;
      }
    }
  }
}