.ChannelsListWrapper {
  width: 1920px;
  height: 420px;
  overflow: hidden;
  position: relative;
  display: flex;

  padding-left: 170px;
  padding-right: 10px;
}

.EmptyFavorites {
  margin: 15px 20px 30px;
}

.ChannelsList {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.ContentWrapper {
  display: flex;
  flex-wrap: wrap;
}

.ChannelCard {
  padding-top: 20px;
  padding-bottom:  20px;
  width: calc(100% / 5);
  //width: 348px;
}