@import "src/styles";

.SideBar {
  height: 1080px;
  //width: 100%;
  display: flex;

  h2 {
    display: flex;
    align-items: center;

    @include card-title-font-styles;
    font-size: 38px;
    padding: 20px 30px;
    height: 100px;
  }

  .ListAndTitleContainer {
    display: flex;
    flex-direction: column;
    //width:400px;
    align-items: flex-start;
    height: 1080px;
    padding-right: 30px;
  }

  .CloseButtonFocusWrapper {
    height: 1080px;
    display: flex;
    align-items: center;
  }

  .SearchButton {
    font-size: 40px;
    height: 100%;
    width: 80px;
    background-color: transparent;
    &:hover {
      background-color: transparent;
      box-shadow: none;
      color: $accent;
    }
  }

  .FocusedSearchButton.FocusedSearchButton {
    background-color: transparent;
    box-shadow: none;
    color: $accent;
  }
}