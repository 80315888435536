@import "@/styles/variables";
@import "@/styles/mixins";

.PromoCardFocusWrapper {
  height: 100%;
  border-color: rgba($gray-500, .3) !important;

  &__Focus {
    border-color: $accent !important;
    box-shadow: $button-active-box-shadow;
  }
}

.PromoCardInner {
  width: 1500px;
  height: 100%;
  position: relative;

  .DescriptionContainer {
    padding: 25px 40px;
    background: linear-gradient(90deg, $primary 0, $primary 40%, rgba($primary,.5) 70%, rgba($primary,0) 80%, transparent);

    &__Channel {
      background: linear-gradient(90deg, $primary 0, $primary 60%, rgba($primary,.5) 70%, rgba($primary,0) 80%, transparent);
    }
  }

  .Description {
    margin: 0;
    @include text-ellipsis(6);
  }

  .ImageBackground {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 60%;
    z-index: -1;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &__Channel {
      width: 42%;
    }
  }
}

.Short {
  width: 1250px;
}

.ChannelDescription {
  width: 100%;
  height: 100%;

  .ProgramContainer {
    width: 60%;
  }

  .ChannelLogo {
    position: relative;
    margin-right: 20px;

    width: 60px;
    outline: none;
    overflow: hidden;
    border-radius: 5px;

    @include flex-centered;

    background-color: #fff;

    img {
      height: 60px;
      object-fit: contain;
    }
  }


  .LogoAndTitle {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  .Title {
    @include content-title-font-styles;
    font-size: 58px;
    line-height: 1;
    @include text-ellipsis(1);
  }
}
