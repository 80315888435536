@import "src/styles/variables";
@import "src/styles/mixins";

.AlertsContainer {
  @include full-width-height-absolute;

  display: flex;
  flex-direction: column;
  align-items: center;

  pointer-events: none;
  background-color: rgba($black, 0);
  transition: background-color linear 0.4s;
  z-index: map-get($z-indexes, 'alerts');

  &__Inner {
    position: relative;
    z-index: map-get($z-indexes, 'alerts');
  }

  &__WithChilds {
    pointer-events: unset;
    background-color: rgba($black, 0.7);
  }
}