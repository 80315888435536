.Search {
  width: 1920px;
  height: 1080px;
  position: relative;

  display: flex;
  flex-direction: column;

  padding-left: 185px;
  padding-right: 15px;
}

.Filters {
  margin-left: 10px;
  margin-right: 15px;
  height: 85px;
}
