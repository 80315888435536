@import "src/styles/variables";
@import "src/styles/mixins";

.TvChannelCard {
  position: relative;
  border: none;
  overflow: unset;
}

.TvChannelCardWrapper {
  // for skeletons
  min-width: 330px;
}

.TvChannelCardInner {
  height: 220px;
  width: 100%;

  .LogoContainer {
    position: relative;

    width: 100%;
    height: 180px;

    overflow: hidden;

    @include flex-centered;
    transition: transform 0.2s ease;

    border-radius: 10px;
    border: 4px solid transparent;
    background-color: #fff;

    img {
      height: 120px;
      object-fit: contain;
    }

    &__Chosen {
      &:before,&:after {
        position: absolute;
        content: '';
        height: 32px;
        width: 32px;
        border-radius: 50%;
        top: 10px;
        right: 10px;
      }
      &:before {
        background-color: $accent;
      }

      &:after {
        color: red;
        z-index: 1;
        background-color: $white;
        clip-path: polygon(17% 54%, 28% 43%, 38% 54%, 70% 22%, 81% 33%, 38% 75%, 17% 54%);
      }
    }
  }

  .Focused {
    border: 4px solid $accent;
    box-shadow: $button-active-box-shadow;

    transform: scale(1.1);
  }

  .FocusedDeletable  {
    transform: scale(1.1);
  }

  .Title {
    height: 40px;
    width: 100%;

    padding: 5px 0 0 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      width: 100%;
      @include text-ellipsis(1);
      @include content-keywords-font-styles;
    }

    i {
      font-size: 24px;
    }
  }
}



@include default-delete-card-inner-styles;