@import "src/styles/variables";
@import "src/styles/mixins";

.ContentCardWrapper {
  padding: 15px 10px;
  width: 410px;
  position: relative;
}

.FocusWrapper {
  &__DeleteMode {
    padding: 0;
    border-color: transparent;
    box-shadow: none;
  }
}

@include content-card-inner-styles;
@include default-delete-card-inner-styles;
