@import "src/styles/variables";
@import "src/styles/mixins";

.CabinetMenuItem.CabinetMenuItem {
  height: 60px;
  width: 100%;
  margin-bottom: 15px;

  padding-left: 20px;
  justify-content: flex-start;

  @include font-styles('Montserrat',  28px, 400, 120% );

  background-color: transparent;
}

.Active {
  background-color: rgba($white, 0.2)!important;
}

.Focused {
  background-color: $accent !important;
}