@mixin text-ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $lines; /* number of lines to show */
  line-clamp: $lines;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

@mixin font-styles ($family, $size, $weight, $line-height, $style: normal) {
  font-family: $family, sans-serif;
  font-size:  $size;
  font-style: $style;
  font-weight: $weight;
  line-height: $line-height;
}

@mixin content-title-font-styles {
  @include font-styles('Roboto',  68px, 700, 120% );
  letter-spacing: -0.68px;
}

@mixin card-title-font-styles {
  @include font-styles('Montserrat',  24px, 600, normal );
}

@mixin content-description-font-styles {
  @include font-styles('Roboto',  24px, 400, 130% );
  letter-spacing: -0.24px;
}

@mixin content-keywords-font-styles {
  @include font-styles('Montserrat',  24px, 600, 120% );
  letter-spacing: -0.24px;
}