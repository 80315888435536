@import "src/styles";
.ModalRenderContainer.ModalRenderContainer {
  display: flex;
  min-width: 400px;
  width: fit-content;
  height: 1080px;
  padding: 0;
  opacity: 1;
  position: absolute;
  will-change: transform;
  transform: translateX(100%);
  transition: transform 0.4s ease-in-out;
  right: 0;
}

.Background.Background {
  opacity: 1;
  //background: linear-gradient(270deg, rgb(17, 16, 42) 0%, rgb(17, 16, 42) 25%,rgba(17, 16, 42, 0.8) 60%, rgba(17, 16, 42, 0.6) 80%, rgba(17, 16, 42, 0.4) 95%, rgba(17, 16, 42, 0) 100%);
  background: linear-gradient(270deg, $primary 0%, $primary 25%,rgba($primary, 0.8) 60%, rgba($primary, 0.6) 80%, rgba($primary, 0.4) 95%, rgba($primary, 0) 100%);
  //background: linear-gradient(270deg, $secondary 0%, $secondary 25%,rgba($secondary, 0.8) 60%, rgba($secondary, 0.6) 80%, rgba($secondary, 0.4) 95%, rgba($secondary, 0) 100%);

}

.ActiveModalRenderContainer.ActiveModalRenderContainer {
  transform: translateX(0);
}

.UnActiveModalRenderContainer.UnActiveModalRenderContainer {
  transform: translateX(100%);
  transition-delay: 0s !important;
}