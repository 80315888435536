.Wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ScrollingWrapper {
  transition: transform;
  transition-duration: 0.4s;
  transition-timing-function: ease;

  will-change: transform;
}