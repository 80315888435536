@import "src/styles";

.PartnerHeader {
  height: 130px;
  margin-left: 170px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @include card-title-font-styles;
  font-size: 46px;
  padding: 0 40px 0 10px;

  font-weight: 500;

  .PartnerTitle {
    max-width: 500px;
  }

  .PartnerLogo {
    height: 80px;

    &>img {
      height: 100%;
      max-width: 300px;
      object-fit: contain;
    }
  }
}