@import "src/styles";

.MoviePlayerPage {
  width: 1920px;
  height: 1080px;

  background-color: black;
}

.MoviePlayer {

}