@import "src/styles/variables";

.CloseButtonFocusWrapper.CloseButtonFocusWrapper {
  height: 1080px;
  display: flex;
  align-items: center;

  .CloseButton.CloseButton {
    font-size: 40px ;
    height: 100%;
    width: 80px;
    background-color: transparent;

    &:hover {
      background-color: transparent ;
      box-shadow: none;
      color: $accent;
    }
  }

  .FocusedCloseButton.FocusedCloseButton {
    background-color: transparent !important;
    box-shadow: none !important;
    color: $accent;
  }
}

