@import "src/styles/variables";
@import "src/styles/mixins";

.ShortProgramList {
  display: flex;
  flex-direction: column;
  height: 300px;
  //margin-bottom: 70px;

  justify-content: space-between;

}