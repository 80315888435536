.LoadMoreButtonWrapper {
  height: 300px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  padding-left: 180px;
}

.SkeletonWrapper {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .SkeletonTitle {
    height: 50px;
    width: 300px;
    padding-bottom: 15px;
    margin-left: 15px;
  }

  .SkeletonBody {
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
  }

  .SkeletonCard {
    height: 100%;
    width: calc(100%/4);
    padding: 0 10px;
  }
}
