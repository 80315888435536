.Home {
  width: 1920px;
  height: 1080px;

  display: flex;
  flex-direction: column;

  &__Header {
    width: 100%;
    height: 580px;
  }

  &__Footer {
    height: 500px;
    width: 1920px;
  }
}