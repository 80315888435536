@import "src/styles/variables";
@import "src/styles/mixins";

.ShortProgramListItem {
  background-color: $secondary;
  height: 90px;
  border-radius: 10px;
  overflow: hidden;

  .ItemContainer {
    display: flex;
    align-items: center;

    height: 100%;
    width: 100%;

    padding: 0 20px;

    border: transparent 4px solid;
    border-radius: 10px;
    overflow: hidden;

    .Title {
      flex-grow: 1;
      @include font-styles('Roboto', 28px, 500, normal);
      @include text-ellipsis(1);
    }

    .Time {
      margin-left: 10px;
      @include font-styles('Roboto', 28px, 500, normal);
    }

    &__Focused {
      border-color: $accent;
    }

    &__Active {
      color: $accent;
    }
  }
}