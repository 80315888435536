@import "src/styles/variables";
@import "src/styles/mixins";

.Button.Button {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  font-size: 42px;

  color: white;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.32px;

  @include flex-centered;

  background-color:  $bg-with-opacity;

  &.Focused, &:hover {
    background-color: $accent;
    box-shadow: $button-active-box-shadow;
  }

  &.Disabled {
    color: rgba(white, 0.5);
    opacity: 0.5;

    &:hover {
      background-color: $bg-with-opacity;
      box-shadow:none;
    }
  }
}