@import "src/styles/variables";
@import "src/styles/mixins";

.ContentCardWrapper {
  padding: 15px 10px;
  width: 578px;

  &:last-of-type {
    margin-right: 20px;
  }
}

.ContentCardFocusWrapper {
}

.ContentCardInner {
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  .FourKLabel {
    position: absolute;

    top: 10px;
    right: 10px;

    width: 40px;

    &>img {
      width: 100%;
      object-fit: cover;
    }
  }

  .Image {
    width: 100%;
    height: 315px;
    @include flex-centered;
    background-color: $secondary;
    overflow: hidden;
    position: relative;

    img {
      position: absolute !important;
      width: 110%;
      object-fit: cover;
      filter: blur(8px);
      -webkit-filter: blur(8px);
    }

    img:last-child {
      position: relative;
      width: unset;
      height: 100%;
      object-fit: cover;
      filter: none;
      -webkit-filter: none;
    }

    div {
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }

  .BoxShadow {
    position: absolute;
    bottom: 0;
    left: -50%;
    width: 200%;
    height: 100%;
    box-shadow: inset 0 -110px 90px 0 rgba($secondary,1);
  }

  .Description {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 20px 20px;
    width: 100%;

    .Info {
      @include text-ellipsis(1);
      @include font-styles('Montserrat', 20px, 600, 100%);
      padding-bottom: 5px;

    }

    .Title {
      height: 28px;
      @include text-ellipsis(1);
      @include font-styles('Montserrat', 28px, 600, 100%);

      div {
        height: 28px;
      }
    }

    .Progress {
      margin-top: 10px;

      height: 5px;
      width: 100%;

      border-radius: 5px;

      background-color: #fff;

      position: relative;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        height: 5px;
        background-color: $accent;
        border-radius: 5px;
        width: var(--progress);
      }
    }
  }
}
