@import "src/styles/mixins";
@import "src/styles/variables";

.FocusWrapper {
  position: relative;
  display: block;
  z-index: 0;
  width: fit-content;
}

.Label {
  color: #929090;
  @include font-styles('Roboto', 24px, 400, 130%);
  letter-spacing: -0.24px;
  margin-bottom: 15px;
}

.Input {
  position: relative;
  z-index: -1;
  outline: none;

  border: 2px solid transparent;
  border-radius: 10px;

  height: 82px;
  width: 560px;

  padding: 24px 20px;

  @include font-styles('Roboto', 28px, 500, 120%);
  letter-spacing: -0.28px;

  &:disabled {
    background-color: rgba(#fff, .2);
    color: rgba(#fff, .6);
  }
}

.Disabled {
  background-color: rgba(#fff, .2);
  color: rgba(#fff, .6);
}

.Focused.Focused {
  border-color: $accent;
  box-shadow: $input-active-box-shadow;
  background-color: #fff;
  color: black;

  &:disabled, .Disabled {
    background-color: #fff;
    color: black;
  }
}

.Active.Active {
  border-color: $accent;
  background-color: #fff;
  color: black;

  &:disabled, .Disabled {
    background-color: #fff;
    color: black;
  }
}
