@import "src/styles";

.Favorites {
  height: 1080px;
  width: 1920px;

  display: flex;
  flex-direction: column;

  overflow: hidden;

  h1 {
    display: flex;
    align-items: center;

    margin-left: 180px;
    height: 120px !important;
    @include card-title-font-styles;
    font-size: 46px;
    padding: 0 35px 0 10px;
    font-weight: 500;
  }

  .FavoritesContentsListOrEmptyContainer {
    height: 560px;
    padding-left: 180px;
  }

  .FavoritesContentsListContainer {
    height: calc(100% - 120px);
    width: 100%;
  }

  .FavoritesContentsListScrollingWrapper {
    display: flex;
    flex-wrap: wrap;
  }
}
