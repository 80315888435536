@import "src/styles/variables";
@import "src/styles/mixins";

$control-panel-background-color: rgba(0, 0, 0, 0.6);

.Overlay {
  @include full-width-height-absolute();

  display: flex;
  flex-direction: column;

  &__Header {
    position: absolute;
    z-index: 200;
    top: 0;
    left: 0;
    right: 0;
    background-color: $control-panel-background-color;

    padding: 30px 50px;

    display: flex;
  }

  &__Body {
    position: relative;
    display: flex;
    flex-grow: 1;
    padding: 150px 20px;
  }

  &__Footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $control-panel-background-color;
  }

  &__PlayPauseButton {
    height: 110px !important;
    width: 110px !important;
    cursor: pointer;
    opacity: 0;
    transition: 0.25s all ease-in-out;
  }

  &__Control {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__LeftSlot, &__RightSlot {
    position: absolute;
    height: calc(100% - 300px);
    display: flex;
    align-items: flex-end;
  }


  &__LeftSlot {
    left: 0;
    padding-left: 40px;

    &__Empty {
      background-color: transparent;
    }
  }

  &__RightSlot {
    right: 0;
    padding-right: 40px;

    &__Empty {
      background-color: transparent;
    }
  }
}

