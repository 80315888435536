@import "src/styles/variables";
@import "src/styles/mixins";

$dark-color: #212529;

.Toast {
  display: flex;
  flex-direction: column;

  width: 600px;

  margin: 10px;

  border-radius: 10px;
  overflow: hidden;

  background-color: $dark-color;
  color: white;

  .ContentContainer {
    display: flex;
    align-items: center;
    padding: 10px 15px;

    &__Icon {
      max-width: 40px;
      @include flex-centered;
      margin-right: 15px;
    }

    &__Content {
      display: flex;
      flex-direction: column;

      &__Title {
        position: relative;
        @include font-styles('Roboto', 30px, 600, 120%);
        @include text-ellipsis(1);
        padding-bottom: 5px;
        margin-bottom: 10px;

        &:before {
          position: absolute;
          content: '';
          width: 100%;
          height: 1px;
          bottom: 0;
          background-color: #fff;
        }
      }

      &__Message {
        @include font-styles('Roboto', 24px, 400, 120%);
        letter-spacing: 1px;
        @include text-ellipsis(5);
      }
    }
  }

  &__Progress {
    position: relative;
    width: 100%;
    height: 6px;
    transition: width linear;

    &:before {
      content: '';
      @include full-width-height-absolute;
      background-color: whitesmoke;
    }
  }

  &__Progress[data-types = 'success'] {
    &:before {
      background-color: $success;
    }
  }

  &__Progress[data-types = 'info'] {
    &:before {
      background-color: $info;
    }
  }

  &__Progress[data-types = 'warning'] {
    &:before {
      background-color: $warning;
    }
  }

  &__Progress[data-types = 'error'] {
    &:before {
      background-color: $danger;
    }
  }

  &__Progress[data-theme = 'colored'] {
    &:before {
      background-color: whitesmoke;
    }
  }

  &__Light {
    background-color: $gray-400;
    color: $dark-color;
  }

  &__Colored {
    color: white;
  }

  &__Colored[data-types = 'info'] {
    background-color: $info;
  }

  &__Colored[data-types = 'success'] {
    background-color: $success;
  }

  &__Colored[data-types = 'warning'] {
    background-color: $warning;
  }

  &__Colored[data-types = 'error'] {
    background-color: $danger;
  }

  svg[data-type = 'info'] {
    fill: $info;
  }

  svg[data-type = 'success'] {
    fill: $success;
  }

  svg[data-type = 'warning'] {
    fill: $warning;
  }

  svg[data-type = 'error'] {
    fill: $danger;
  }

  svg[data-type = 'default'] {
    fill: white;
  }
}

