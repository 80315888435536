@import "src/styles";

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 860px;
  margin: auto;

  .ImageContainer {
    display: flex;
    justify-content: center;

    height: 225px;

    img {
      height: 100%;
    }
    div {
      height: 100%;
      width: 225px;
    }

    margin-bottom: 40px;
  }

  hgroup {
    text-align: center;
    max-width: 760px;
    h2 {
      @include font-styles('Montserrat', 36px, 600, 125%);
      margin-bottom: 40px;
    }

    p {
      @include font-styles('Roboto', 28px, 500, 120%);
    }

    margin-bottom: 80px;
  }

  .ActionsContainer {
    display: flex;
    justify-content: space-between;
    height: 80px;
    width: 100%;
  }

  .ActionButton {
    height: 100%;
    width: 400px;
  }
}