@import "src/styles/variables";
@import "src/styles/mixins";

.Sessions {
  --warning-height: 240px;
  --header-height: 200px;
  --list-height: calc(100% - var(--warning-height) - var(--header-height));

  height: 100%;
  padding: 40px 120px 0;

  .Warning {
    height: var(--warning-height);

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .Header {
    height: var(--header-height);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 40px 15px 30px;

    h1 {
      display: flex;

      @include font-styles('Roboto', 50px, 500, 120%);
      margin-bottom: 30px;
      letter-spacing: -0.68px;
      @include text-ellipsis(1);
    }

    p {
      width: 100%;

      @include font-styles('Roboto', 32px, 500, 120%);
      letter-spacing: -0.28px;

      color: rgba(white, 0.6);
    }
  }

  .List {
    height: var(--list-height);
  }

  .ScrollContainer {
    display: flex;
    flex-wrap: wrap;
  }

  .CardWrapper {
    width: calc(100% / 2);
  }
}