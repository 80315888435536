@import "src/styles/variables";

.Actions {
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 20px;
  }
}

.Action.Action {
  height: 70px;
  width: 280px;
  font-size: 24px;
  border: 3px solid $bg-with-opacity;
  background-color: transparent;

  &:hover {
    border-color: $accent;
    background-color: transparent;
    box-shadow: none;
  }

}

.Focused.Focused {
  border-color: $accent;
  background-color: transparent !important;
  box-shadow: none !important;
}