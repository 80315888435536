.Spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  img {
    //display: block;
    //width: 100%;
    //height: 100%;
  }
}