.Reset {
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
  //color: white;
  display: block;
  position: relative;
}

.Loader {
  img {
    width: 60px;
    height: 60px;
  }
}