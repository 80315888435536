.Info {
  min-width: 66px;
  height: 100%;

  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 10px;

  font-family: Montserrat, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.25px;
  text-transform: uppercase;
}