.Enter {
  animation: bounceInRight 1s ease;
}

.Exit {
  animation: bounceOutRight 0.4s ease;
}

.Exited {
  opacity: 0;
  transition: width ease 0.2s;
  height: 0;
}

@mixin timing-function {
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    @include timing-function;
  }
  from {
    opacity: 0;
    //transform: translate3d(3000px, 0, 0);
    transform: translateX(3000px);
  }
  60% {
    opacity: 1;
    //transform: translate3d(-25px, 0, 0);
    transform: translateX(-25px);
  }
  75% {
    //transform: translate3d(10px, 0, 0);
    transform: translateX(10px);
  }
  90% {
    //transform: translate3d(-5px, 0, 0);
    transform: translateX(-5px);
  }
  to {
    transform: none;
  }
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translateX(-20px);
  }
  to {
    opacity: 0;
    transform: translateX(2000px);
  }
}
