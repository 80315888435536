.Keyboard {
  display: flex;
  justify-content: space-between;
  width: 1290px;
  height: 320px;
}

.Keys {
  width: 1040px;

  display: flex;
  flex-wrap: wrap;
}

.NumpadKeys {
  width: 240px;

  display: flex;
  flex-wrap: wrap;
}