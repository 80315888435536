.ScrollingWrapper {
  padding-left: 180px;
}

.FocusWrapper {
  height: 320px;
}

.InnerContainer {
  padding-top: 20px;
  padding-bottom:  0;
  width: 330px;

  &:last-child {
    margin-right: 20px;
  }
}
