@import "src/styles/variables";
@import "src/styles/mixins";

.CardWrapper {
  padding: 15px 30px 15px 0;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    //margin-right: 20px;
  }
}


.CardFocusWrapper {
  box-shadow: none;
  border: none;
}

.CardInner {
  width: 100%;
  //max-height: 140px;
  padding: 16px;

  display: flex;
  align-items: flex-start;
  //background-color: red;

  .ReadIndicator {
    position: relative;

    min-width: 40px;
    width: 40px;
    height: 12px;

    &__Active {
      &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $accent;
        top: 8px;
        left: 8px;
      }
    }
  }

  .Title {
    min-width: 330px;
    width: 330px;

    @include font-styles('Montserrat',  24px, 600, normal );
    @include text-ellipsis(3);
  }

  .Variant {
    margin-top: 8px;
    margin-right: 40px;

    min-width: 34px;
    width: 34px;
    height: 34px;

    outline: none;
    box-shadow: 0 0 10px var(--bg) ;
    background-color: var(--bg);
    color: $secondary;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 1;
    text-align: center;

    &__Info {
      --bg: #01afe2;
    }
    &__Success {
      --bg: #4caf50;
    }
    &__Warning {
      --bg: #ff9800;
    }
    &__Danger {
      --bg: #f44336;
    }
  }

  .Description {
    //max-width: 1140px;
    flex-grow: 1;
    font-size: 24px;
    @include text-ellipsis(3);
  }

  .Action {
    //margin-top: 12px;
    align-self: center;
    margin-left: 40px;
    min-width: 65px;
    width: 65px;
    height: 65px;
    background-color: $bg-with-opacity;

    @include flex-centered;

    border-radius: 50%;
    color: white;
    font-size: 30px;

    &>i {
      margin-left: 5px;
    }
  }

  &__Readed {
    color: rgba(white, 0.6);
  }

  &__Focused, &:hover {
    background-color: $bg-with-opacity;

    .Action {
      background-color: $accent;
    }
  }
}