@import "src/styles";

.Contents {
  height: 100%;
  width: 100%;
}

.EditMode {
  height: 70px;
  margin-left: -10px;
  position: relative;
  font-weight: 500;

  .Checkbox {
    width: 550px;
  }
}

.Title {
  margin-left: 15px;
  @include card-title-font-styles;
  font-size: 30px;
  height: 40px;
  margin-bottom: 10px;
}

.Empty {
  margin-top: 30px;
  height: 390px;
  width: 1710px;
}

.Card.Card {
  // Важно в пикселях при процентах проблема с фокусами
  width: 430px;
}