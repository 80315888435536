.ProgramDatesSliderFocusWrapper {
  position: relative;
  width: 1920px;
  margin: 0 -30px;
  height: 100px;

  &::before {
    position: absolute;
    content: '';
    height: 1px;
    width:100%;
    left: 0;
    //top:210px;
    bottom: 0;
    //opacity: 0.2;
    background-color: #FFF;
  }
}

.ProgramDatesSliderScrollingWrapper {
  padding: 0 0 0 120px;
  height: 100%;
}

.ProgramDatesSliderContentWrapper {
  display: flex;
  align-items: center;
  padding-right: 50px;
}
