@import "src/styles/variables";
@import "src/styles/mixins";

.Player {
  //position: relative;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  background-color: $black;

  @include full-width-height-absolute();

  &__Video {
    position: relative;
    width: 100%;
    height: 100%;
    background-color:  $black;

    &:-webkit-full-screen {
      background-color: $black;
      z-index: 2147483647;
    }

    video {
      @include full-width-height-absolute();
      background: url("../../../assets/images/loaders/loader-0.svg") center center no-repeat;
    }
  }

  .Winter {
    video {
      background: url("../../../assets/images/loaders/loader-3.svg") center center no-repeat;
    }
  }
}