@import "src/styles/variables";


.Wrapper {
  width: 1920px;
  background-color: $secondary;
}

.ScrollingWrapper {
  padding: 15px 0 15px 175px;
  background-color: $secondary;
}