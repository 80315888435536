.DefaultLayout {
  position: relative;

  width: 1920px;
  height: 1080px;

  display: flex;

  .MenuContainer {
    width: 170px;
    position: relative;
    z-index: 1;
  }

  .ContentRedirectContainer {
    position: absolute;
    width: 1920px;
    top:0;
    left: 0;
    height: 1080px;
    z-index: 0;

    .ContentRedirect {
      position: relative;
      z-index: 2;
      overflow-x: visible;
      display: flex;

      .ContentContainer {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.MenuContainer {
  //width: 170px;
  //position: relative;
  //z-index: 1;


  position: absolute;
  top: 0;
  left: 0;
  height: 1080px;
  width: 170px;
}
