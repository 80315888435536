@import "src/styles/variables";
@import "src/styles/mixins";

.GenreCardWrapper {
  padding: 15px 10px;

  &:last-of-type {
    margin-right: 20px;
  }
}

.GenreCardFocusWrapper {
}

.GenreCardInner {
  width: 270px;
  height: 180px;
  background-color: $secondary;
  padding: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .Image {
    @include flex-centered();
    flex-grow: 1;
    min-width: 80px;
    min-height: 80px;

    img {
      object-fit: contain;
      min-width: 80px;
      max-width: 180px;
      height: 80px;
      max-height: 80px;
    }

    div, svg {
      width: 80px;
      height: 80px;
    }

  }

  .CardTitle {
    height: 30px;

    h4 {
      @include text-ellipsis(1);
      @include content-keywords-font-styles;
    }

    &__Hidden {
      display: none;
      height: 0;
    }
  }
}
