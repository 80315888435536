@import "src/styles";

.ChannelNeedPackage {
  width: 956px;
  height: 536px;

  border-radius: 10px;
  border: 3px solid transparent;

  overflow: hidden;

  .BG {
    //background: linear-gradient(90deg, rgb(17, 16, 42) 0%, rgb(17, 16, 42) 25%, rgba(17, 16, 42, 0.8) 60%, rgba(17, 16, 42, 0.6) 80%, rgba(17, 16, 42, 0) 100%);
    background: linear-gradient(90deg, $primary 0%, $primary 25%, rgba($primary, 0.8) 60%, rgba($primary, 0.6) 80%, rgba($primary, 0) 100%);

    height: 100%;
    width: 100%;
    display: flex;

    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    padding: 150px 120px;
  }

  &__Focused {
    border: 3px solid $accent;
    box-shadow: $button-active-box-shadow;
  }

  .Title {
    @include flex-centered;
    height: 60px;
    text-align: center;

    @include font-styles('Montserrat', 36px, 600, 120%);
  }

  .Action {
    height: 100px;
    width: 400px;
  }
}