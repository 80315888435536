@import "src/styles/variables";

.EmptyLayout {
  width: 1920px;
  height: 1080px;
  max-height: 1080px;
  max-width: 1920px;
  background-image: url('../../assets/images/auth/bg.webp');
  background-position: center center;
  background-size: cover;
  .Content {
    width: 1920px;
    height: 1080px;
    max-height: 1080px;
    max-width: 1920px;
    background: $linear-gradient-bg;
  }
}
