@import "src/styles/variables";
@import "src/styles/mixins";

.List.List {
  flex-grow: 1;
  //width: unset;
  height: 910px;
  padding-top: 7px;
}

.EpisodeButtonFocusWrapper {
  padding: 8px 0 8px 15px;
  position: relative;
  &:last-child {
    padding-bottom: 50px;
  }

  &:before {
    position: absolute;
    top: 20px ;
    left: 800px;

    transform: translate(-100%,);

    @include font-styles('Roboto', 24px, 400, 120%);
    content: var(--releasedAt);
  }
}

.EpisodeButton {
  width: 800px;
  height: 160px;
  padding: 10px 15px;

  &__Inner {
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
  }

  .EpisodeTitle {
    @include font-styles('Roboto', 34px, 400, 120%);
    margin-bottom: 20px;
  }

  .EpisodeDescription {
    @include font-styles('Roboto', 22px, 400, 120%);
    @include text-ellipsis(3);
    text-align: left;
  }
}

.Active.Active {
  background-color: rgba($accent, 0.6);
}

.Focused {
  background-color: $accent;
}
