@import "src/styles/variables";
@import "src/styles/mixins";

.ToastsContainer {
  @include full-width-height-absolute;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  pointer-events: none;

  &__Inner {
    position: relative;
    z-index: map-get($z-indexes, 'toasts');
  }
}