@import "src/styles";

.Error {
  width: 100%;
  height: 100%;

  @include flex-centered;
  flex-direction: column;

  text-align: center;

  h2 {
    @include font-styles('Roboto', 36px, 400, 120%);
  }

  img {
    width: 300px;
    height: 300px;

    margin: 0 auto;
  }

  .BackAction {
    height: 80px;
    padding: 0 35px;
    margin-top: 50px;
  }
}