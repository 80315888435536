@import "src/styles";

.Checkbox {
  display: flex;
  align-items: center;

  height: 68px;

  color: white;
  font-size: 28px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.32px;
  border-radius: 10px;
  padding: 0 10px;

  .Label {
    margin-left: 10px;
    display: flex;
    align-items: center;
    width: 100%;

    span {
      margin-left: 15px;
      flex-grow: 1;
      @include text-ellipsis(1);
    }
  }

  .Input {
    width: 32px;
    height: 32px;
    min-width: 32px;
    //accent-color: #01afe2;
    position: relative;

    appearance: none;
    -webkit-appearance: none;

    @include flex-centered;

    border: 3px solid #fff;
    border-radius: 5px;
    outline: none;

    background: #100F23;

    &:before {
      //content: "\e90a";
      content: "";
      //font-family: 'itv3-icons-fill';
      position: absolute;
      width: 30px;
      height: 30px;
      text-align: center;
      clip-path: polygon(17% 54%, 28% 43%, 38% 54%, 70% 22%, 81% 33%, 38% 75%, 17% 54%);
      transform: scale(0);
      background-color: #fff;
      top: calc(50% - 15px);
    }

    &:checked {
      background-color: $accent;
      border-color: $accent;
    }

    &:checked::before {
      transform: scale(0.9);
    }
  }

  .Rounded {
    .Input {
      border-radius: 50%;

      &:before {
        clip-path: circle(27%);
      }

      &:checked {
        background-color: $accent;
        border-color: $accent;
      }

      &:checked::before {
        transform: scale(0.9);
      }
    }
  }

  &__Focused {
    background: rgba(255, 255, 255, 0.10);

    .Input {
      border-color: $accent;
    }
  }

  &__Disabled {
    opacity: 0.5;
  }
}